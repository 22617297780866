import { sample } from "lodash";

const configs = {
  topType: [
    "NoHair",
    "Eyepatch",
    "Hat",
    "Hijab",
    "Turban",
    "WinterHat1",
    "WinterHat2",
    "WinterHat3",
    "WinterHat4",
    "LongHairBigHair",
    "LongHairBob",
    "LongHairBun",
    "LongHairCurly",
    "LongHairCurvy",
    "LongHairDreads",
    "LongHairFrida",
    "LongHairFro",
    "LongHairFroBand",
    "LongHairNotTooLong",
    "LongHairShavedSides",
    "LongHairMiaWallace",
    "LongHairStraight",
    "LongHairStraight2",
    "LongHairStraightStrand",
    "ShortHairDreads01",
    "ShortHairDreads02",
  ],
  accessoriesType: [
    "Blank",
    "Kurt",
    "Prescription01",
    "Prescription02",
    "Round",
    "Sunglasses",
    "Wayfarers",
  ],
  // hatColor: [
  //   "Black",
  //   "Blue01",
  //   "Blue02",
  //   "Blue03",
  //   "Gray01",
  //   "Gray02",
  //   "Heather",
  //   "PastelBlue",
  //   "PastelGreen",
  //   "PastelOrange",
  //   "PastelRed",
  //   "PastelYellow",
  //   "Pink",
  //   "Red",
  //   "White",
  // ],
  hairColor: [
    "Auburn",
    "Black",
    "Blonde",
    "BlondeGolden",
    "Brown",
    "BrownDark",
    "PastelPink",
    "Platinum",
    "Red",
    "SilverGray",
  ],
  facialHairType: [
    "Blank",
    "BeardMedium",
    "BeardLight",
    "BeardMajestic",
    "MoustacheFancy",
    "MoustacheMagnum",
  ],
  // facialHairColor: [
  //   "Auburn",
  //   "Black",
  //   "Blonde",
  //   "BlondeGolden",
  //   "Brown",
  //   "BrownDark",
  //   "Platinum",
  //   "Red",
  // ],
  clotheType: [
    "BlazerShirt",
    "BlazerSweater",
    "CollarSweater",
    "GraphicShirt",
    "Hoodie",
    "Overall",
    "ShirtCrewNeck",
    "ShirtScoopNeck",
    "ShirtVNeck",
  ],
  clotheColor: [
    "Black",
    "Blue01",
    "Blue02",
    "Blue03",
    "Gray01",
    "Gray02",
    "Heather",
    "PastelBlue",
    "PastelGreen",
    "PastelOrange",
    "PastelRed",
    "PastelYellow",
    "Pink",
    "Red",
    "White",
  ],
  // graphicType: [
  //   "Bat",
  //   "Cumbia",
  //   "Deer",
  //   "Diamond",
  //   "Hola",
  //   "Pizza",
  //   "Resist",
  //   "Selena",
  //   "Bear",
  //   "SkullOutline",
  //   "Skull",
  // ],
  eyeType: [
    "Close",
    "Cry",
    "Default",
    "Dizzy",
    "EyeRoll",
    "Happy",
    "Hearts",
    "Side",
    "Squint",
    "Surprised",
    "Wink",
    "WinkWacky",
  ],
  eyebrowType: [
    "Angry",
    "AngryNatural",
    "Default",
    "DefaultNatural",
    "FlatNatural",
    "RaisedExcited",
    "RaisedExcitedNatural",
    "SadConcerned",
    "SadConcernedNatural",
    "UnibrowNatural",
    "UpDown",
    "UpDownNatural",
  ],
  mouthType: [
    "Concerned",
    "Default",
    "Disbelief",
    "Eating",
    "Grimace",
    "Sad",
    "ScreamOpen",
    "Serious",
    "Smile",
    "Tongue",
    "Twinkle",
    "Vomit",
  ],
  skinColor: [
    "Tanned",
    "Yellow",
    "Pale",
    "Light",
    "Brown",
    "DarkBrown",
    "Black",
  ],
};

const configsKeys = Object.keys(configs);
const BASE_URL = "https://avataaars.io/";

export const generateRandomAvatarUrl = () => {
  let queryString = "?";
  configsKeys.forEach((key, index) => {
    const values = configs[key];
    if (index === 0) {
      queryString = queryString + key + "=" + sample(values);
    } else {
      queryString = queryString + "&" + key + "=" + sample(values);
    }
  });
  return BASE_URL + queryString;
};
